import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { signIn, signOut, getCurrentUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { catchError, debounceTime, mapTo } from 'rxjs/operators';
import { environment } from 'src/environment/environment';
import { AuthUser } from '@aws-amplify/auth';
import { fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';


export interface AuthState {
  state: string; // signIn, signUp, signedIn, newPassword, signedOut, forgotPassword, verificationFailed, confirmSignIn, confirmSignUp
  user: any;

}
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  private _authState = new Subject<AuthState>();
  private baseUrl = environment.apiUrl;
  private isEmailAvailable = new BehaviorSubject<boolean>(false);
  isSignInHandled: any = false;
  constructor(private router: Router, private http: HttpClient) {
    this.listenToAuthEvents();
    this.checkAuthState();



  }


  // Method to check the current authentication state
  checkAuthState(): void {
    getCurrentUser()
      .then(() => {
        this.isLoggedInSubject.next(true)
        console.log("current user called")
      })
      .catch(() => this.isLoggedInSubject.next(false));
  }

  // Observable for components to get the current auth state reactively true/false
  get isLoggedIn$() {
    return this.isLoggedInSubject.asObservable();
  }

  // Observable for components to get the current auth state reactively
  get authStateChange$() {
    return this._authState.asObservable();
  }

  signOut(): void {
    console.log('Signed out successfully');
    signOut()
      .then(() => {

        localStorage.removeItem('token');
        localStorage.removeItem('User_Group');
        localStorage.clear();
        localStorage.removeItem('Avatar');
        localStorage.setItem('isLoggedIn', 'false');


        this.router.navigateByUrl('pages/dashboard').then(() => {

          console.log('calling router -------------> dashboard')
          // window.location.reload();
          this.setAuthState({ state: 'signIn', user: null });
          this.authStatus('signedOut');
          localStorage.setItem('IsLogged', 'false');

        });
      })
      .catch(err => console.error('Error signing out: ', err));
  }


  authStatus(state: string): void {
    switch (state) {
      case "signedIn":
        this.isLoggedInSubject.next(true);
        break;
      case "signedOut":
        this.isLoggedInSubject.next(false);
        break;
      default:
        this.isLoggedInSubject.next(false);
    }
  }

  setAuthState(state: AuthState) {
    this._authState.next(state);
  }


  private handleError(error: any) {
    console.error('An error occurred:', error.error);
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }


  listenToAuthEvents() {
    console.log("auth listining called");

    Hub.listen('auth', (data) => {
      console.log("auth listining called");
      const { payload } = data;
      console.log('Received auth event:', payload.event);
      console.log('Auth data:', data);

      switch (payload.event) {
        case 'signInWithRedirect':
        case 'signedIn':
          console.log('Sign-in data:', data);
          // this.handleSignIn(data.payload)
          //   .catch(err => console.error('Sign-in handling error:', err));
          if (!this.isSignInHandled) {
            this.isSignInHandled = true;
            console.log('Sign-in data:', data);
            this.handleSignIn(data.payload)
              .catch(err => console.error('Sign-in handling error:', err))
              .finally(() => this.isSignInHandled = false);
          }
          break;
        case 'signedOut':
          this.handleSignOut();
          break;
      }
    });
    console.log("hub event completed");
  }



  async handleSignIn(data: any) {

    const sessionData = await fetchAuthSession();

    console.log('Session data:', sessionData);
    // const signInUserSession = data.signInUserSession;
    if (!sessionData) {
      console.error('No SessionData available');
      return;
    }

    // Detailed logging for each step
    console.log('AccessToken:', sessionData.tokens?.accessToken.toString());
    console.log('User Groups:', sessionData.tokens?.accessToken.payload['cognito:groups']);
    const userGroup = sessionData.tokens?.accessToken.payload['cognito:groups']?.toString()
    localStorage.setItem('LoggedFrom', 'Federated');
    localStorage.setItem('token', sessionData.tokens?.accessToken.toString() || '');
    localStorage.setItem('User_Group', JSON.stringify(userGroup));


    let email = String(sessionData.tokens?.idToken?.payload['email'] || '');
    email = email.toLowerCase();

    console.log('Email on auth:', email);
    localStorage.setItem('User_Email', email);
    this.setIsEmail(true);
    const fullName = String(sessionData.tokens?.idToken?.payload?.['name']);
    const userName = String(sessionData.tokens?.accessToken.payload['username']);
    const [FName, ...rest] = fullName?.split(' ') || [];
    const LName = rest.join(' ') || '';
    // const FName = String(sessionData.tokens?.idToken?.payload?.['name'])
    // const LName = String(sessionData.tokens?.idToken?.payload?.['middle_name'])
    const [logInType] = userName.split('_');

    console.log('Parsed Names:', FName, LName);
    console.log('Login Type:', logInType);

    localStorage.setItem('User_Email', email);
    localStorage.setItem('IsLogged', 'true');
    localStorage.setItem('User_Fname', FName);
    localStorage.setItem('User_Lname', LName);

    await this.setAuthState({ state: 'signedIn', user: { email: email } });
    await this.authStatus('signedIn');

    // Perform operations based on logInType
    if (logInType === 'google' || logInType === 'Google') {
      console.log('Google sign-in process starts');
      await (await this.signUp({
        email,
        name: FName,
        middle_name: LName,
        loggedFrom: 'Google'
      })).toPromise()
        .then(() => {
          this.getuserdetails({ email: email }).subscribe(res => {
            const udata = res.data;
            console.log('get user details : ', udata);
            localStorage.setItem('UserData', JSON.stringify(udata));
          }, (error) => {
            console.error("error occured while getting userdata", error)
          });
          console.log('Google sign-up successful')
        })
        .catch(error => console.error('Google sign-up error:', error));
    }

    // Additional redirection or storage setting logs
    const redirectUrl = localStorage.getItem('GroupId');
    // const redirectUrl = sessionStorage.getItem('GroupIdforLog');
    if (redirectUrl) {
      console.log(redirectUrl);
      this.router.navigateByUrl(`/pages/coursedetails?gid=${redirectUrl.split('#')[1]}`);
    } else {
      this.router.navigate(['pages']);
    }

  }

  async handleSignOut() {
    console.log('Sign out initiated');
    localStorage.clear();
    this.router.navigate(['/pages']).then(() => {
      console.log('Navigation to login completed');
    }).catch(error => console.error('Sign out navigation error:', error));
  }



  //=================================API calls=====================================================



  async signUp(data: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return await this.http.post<any>(`${this.baseUrl}/createStudent`, data, { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  getuserdetails(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(`${this.baseUrl}/getStudentData`, data, { headers: headers });
  }


  validateUserEmail(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(`${this.baseUrl}/validateEmail`, data, { headers: headers });
  }

  setIsEmail(email_state: boolean) {
    this.isEmailAvailable.next(email_state)
  }

  get isEmail$() {
    return this.isEmailAvailable.asObservable();
  }




}
