import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuthState, AuthenticationService } from 'src/app/authentication/authentication.service';
import { HeaderService } from './header.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatMenuTrigger } from '@angular/material/menu';
import { ChangeDetectorRef } from '@angular/core'
import { AvatarService } from 'src/app/pages/profile/avatar-popup/avatar.service';
import { jwtDecode } from 'jwt-decode';
import { getCurrentUser } from '@aws-amplify/auth';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  isLoggedIn$ = this.authService.isLoggedIn$;
  authStateChange$ = this.authService.authStateChange$;
  isLoggedIn: boolean = false;
  private authSubscription!: Subscription;
  isDashboard: boolean = false;
  deviceInfo: any;
  isMobile: boolean = false;
  avatar: any = false;
  userDetails: any = null;
  initial = '';
  loggedEmail!: string;
  loggedFirstName!: string;
  username = '';
  displayname!: string;
  emailActionPerformed: boolean = false;
  constructor(private router: Router, private authService: AuthenticationService,
    private headerService: HeaderService,
    private deviceService: DeviceDetectorService,
    private avatarService: AvatarService,
    private changeDetectorRef: ChangeDetectorRef) {
    this.username = 'P';
    this.displayname = 'P';
  }

  async ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    const token = this.decodeToken();
    console.log('token', token);
    console.log('deviceInfo', this.deviceInfo);
    this.checkCurrentRoute();
    this.authSubscription = this.authService.isLoggedIn$.subscribe(
      async (loggedIn) => {
        this.isLoggedIn = loggedIn;
        console.log(this.isLoggedIn)
        localStorage.setItem('isLoggedIn', JSON.stringify(this.isLoggedIn));
      }
    );
    // check for current authenticated user to init authState
    getCurrentUser()
      .then(async (user: any) => {
        console.debug('has authenticated user', user);
        // await this.authService.setAuthState({ state: 'signedIn', user: { email: this.email } });
        await this.authService.authStatus('signedIn');
      })
      .catch(async (err: any) => {
        console.log('no authenticated user', JSON.stringify(err));
        await this.authService.setAuthState({ state: 'signedOut', user: null });
        await this.authService.authStatus('signedOut');
      });
    const emailS = localStorage.getItem('User_Email');
    this.authService.isEmail$.pipe(
      distinctUntilChanged(), // Ensure distinct values only
      filter(isEmailAvailable => isEmailAvailable && !this.emailActionPerformed) // Only proceed if isEmailAvailable is true
    )
      .subscribe(isEmailAvailable => {
        console.log("Email set state", isEmailAvailable);
        const emailS = localStorage.getItem('User_Email');
        console.log("user mail", emailS);

        if (emailS && !this.emailActionPerformed) {
          this.authService.getuserdetails({ email: emailS }).subscribe(
            res => {
              const udata = res.data;
              console.log('get user details : ', udata);
              this.loggedEmail = udata.email;
              this.loggedFirstName = udata.FirstName;
              // this.displayname = this.loggedFirstName.split(' ').map(word => word[0]).join('');

              setTimeout(async () => {
                this.userDetails = JSON.parse(localStorage.getItem('UserData')!);
                console.log('user details- header', this.userDetails);
                await this.getAvatar();
                this.changeDetectorRef.detectChanges();
                this.emailActionPerformed = true;
              }, 1200);
            });
        }

      });

    setTimeout(async () => {
      this.username = localStorage.getItem('User_Fname')! || 'P';
      this.displayname = this.username.split(' ').map(word => word[0]).join('')
      console.log("display name", this.displayname);
    }, 4000);
    console.log('username', this.username)

    this.avatarService.avatarChanges().subscribe(avatar => {
      this.avatar = avatar;
    });

    this.avatar = localStorage.getItem('Avatar');
    console.log('avatar', this.avatar);

  }

  ngOnDestroy() {
    // Unsubscribe to ensure no memory leaks
    this.authSubscription.unsubscribe();
  }

  signOut(): void {
    // this.headerService.buttonLogoutClicked.emit();
    this.authService.signOut();
  }

  clickProfile() {
    console.log('profile clicked');
    this.router.navigateByUrl('/pages/profile');
  }
  goToLogin() {
    console.log('auth clicked');
    this.router.navigateByUrl('/auth');
  }

  onSearchChange(searchValue: any): void {
    this.headerService.changeSearch(searchValue);
  }

  checkCurrentRoute() {
    const currentUrl = this.router.url;
    if (currentUrl === '/pages/dashboard') {
      this.isDashboard = true;

    } else {
      this.isDashboard = false;
    }
    this.router.events.pipe(
      filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isDashboard = event.urlAfterRedirects.includes('/dashboard') ? true : false;
      console.log('isDashboard:', this.isDashboard);
    });

  }

  clickLogo() {
    this.router.navigateByUrl('/pages/dashboard');
  }

  clickFeedback() {
    this.router.navigateByUrl('/pages/feedback');
  }

  getUserInitialsFromSession() {
    const name = localStorage.getItem('User_Fname') ?? 'test';
    console.log('this.loggedFirstName :', this.loggedFirstName);
    if (this.loggedFirstName) {
      const initials = this.loggedFirstName.split(' ').map(word => word[0]).join('');
      return initials.toUpperCase();
    }
    return '';
  }



  async getAvatar() {
    // setTimeout(async () => {
    console.log(' called Avatar');
    if (this.isLoggedIn && this.loggedEmail) {
      const email = localStorage.getItem('User_Email') || "";
      const data = {
        email: this.loggedEmail
      }
      await this.headerService.getAvatar(data).subscribe((res) => {
        console.log("Avatar", res);
        if (res.data) {
          localStorage.setItem('Avatar', res.data);
          this.avatar = res.data;
          this.changeDetectorRef.detectChanges();
        }

      })
    }
  }

  decodeToken(): any {
    const token = localStorage.getItem('token')! || '';
    try {
      if (localStorage.getItem('token')) {
        return jwtDecode(token);
      } else {
        return null;
      }
    } catch (Error) {
      console.error('Invalid token', Error);
      return null;
    }
  }
}
