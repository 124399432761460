import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private searchTerms = new BehaviorSubject<string>('');
  buttonLogoutClicked: EventEmitter<void> = new EventEmitter();
  currentSearch = this.searchTerms.asObservable();

  constructor(private http: HttpClient) { }

  changeSearch(term: string) {
    this.searchTerms.next(term);
  }

  getAvatar(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/getuseravatar`, data)
  }
}