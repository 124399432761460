import { Component, OnInit, HostListener  } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from './authentication/authentication.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  template: `
    <app-header *ngIf="!authPage"></app-header>
     <div [style.paddingBottom.px]="footerHeight">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="isLoggedIn && isMobile" class="mobile-footer" (footerHeight)="adjustContentHeight($event)"></app-footer>
    `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'quizhub-client';
  authPage: boolean = false;
  footerHeight: number = 0;
  isLoggedIn: boolean = false;
  isMobile: boolean = false;
  constructor(private router: Router,private authService: AuthenticationService, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    console.log('is mobile', this.isMobile);
    this.authService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      console.log("Updated isLoggedIn:", this.isLoggedIn);
    });
    this.router.events.pipe(
      filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.authPage = event.urlAfterRedirects.includes('/auth');
    });
  }
  adjustContentHeight(height: number) {
    this.footerHeight = height;
  }
}
