import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { fetchAuthSession } from 'aws-amplify/auth';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
@Injectable()
export class AuthJWTInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return this.getJwtToken().pipe(
            mergeMap(token => {
                if (token !== 'null') {
                    const JWT = `${token}`;
                    req = req.clone({
                        setHeaders: {
                            Authorization: JWT,
                        },
                    });
                }
                return next.handle(req);
            })
        );
    }

    // getJwtToken(): Observable<any> {
    //     return from(
    //         new Promise((resolve, reject) => {
    //             Auth.currentSession().then((session) => {
    //                 if (!session.isValid()) {
    //                     resolve(null);
    //                 } else {
    //                     resolve(session.getIdToken().getJwtToken());
    //                 }
    //             })
    //                 .catch(() => {
    //                     return resolve(null);
    //                 });
    //         })
    //     );
    // }

    getJwtToken(): Observable<string | null> {
        return from(
            new Promise<string | null>((resolve) => {
                fetchAuthSession().then((session) => {
                    if (session.tokens?.idToken?.toString()) {
                        resolve(session.tokens.idToken.toString());
                    } else {
                        resolve(null);
                    }
                }).catch(() => {
                    resolve(null);
                });
            })
        );
    }
}
