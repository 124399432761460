// avatar.service.ts

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private avatarData: string | null = null;
  private avatarSubject = new Subject<string | null>();

  constructor() { }

  setAvatarData(avatar: string | null) {
    this.avatarData = avatar;
    this.avatarSubject.next(avatar);
  }

  getAvatarData() {
    return this.avatarData;
  }

  avatarChanges() {
    return this.avatarSubject.asObservable();
  }
}
