
    <footer class="footer">
        <nav class="nav">
        <a mat-button class="nav-item" routerLink="/dashboard" (click)="setActive('dashboard')">
            <mat-icon svgIcon="custom_icon" *ngIf="activeItem !== 'dashboard'"></mat-icon>
            <mat-icon *ngIf="activeItem === 'dashboard'">dashboard</mat-icon>
            <span>Dashboard</span>
        </a>
        <a mat-button class="nav-item" routerLink="/feedback" (click)="setActive('feedback')">
            <mat-icon *ngIf="activeItem === 'feedback'">bookmark</mat-icon>
            <mat-icon *ngIf="activeItem !== 'feedback'">bookmark_outline</mat-icon>
            <span>FeedBack</span>
        </a>
        <a mat-button class="nav-item" routerLink="/profile" (click)="setActive('profile')">
            <mat-icon *ngIf="activeItem === 'profile'">person</mat-icon>
            <mat-icon *ngIf="activeItem !== 'profile'">person_outline</mat-icon>
            <span>Profile</span>
        </a>
        </nav>
    </footer>

  