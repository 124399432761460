
export const environment: any = {
  // siteUrl: 'http://localhost:4200',
  // apiUrl: 'http://localhost:8010/proxy',
  // apiUrl: 'http://localhost:3000',
  // apiUrl: 'https://admin-api-dev.tutoring.panaceata.com/dev',
  // siteUrl: 'https://admin-dev.tutoring.panaceata.com',
  apiUrl: 'https://api.iqsparker.com/stage',
  production: false,
  baseUrl: '',
  useHash: false,
  orgId: '9abbb7c2-7f11-4a9c-bb54-panaceata441',
  version: '1.1.0'
};

export const awsconfig = {
  aws_project_region: 'eu-north-1',
  aws_cognito_region: 'eu-north-1',
  aws_user_pools_id: 'eu-north-1_mauroaE5P',
  aws_user_pools_web_client_id: '6pdmh4qt04q3vj3na5fr8qaj6n',
  oauth: {
    domain: 'quizhub.auth.eu-north-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: 'https://iqsparker.com/pages/dashboard',
    redirectSignOut: 'https://iqsparker.com/pages/dashboard',
    responseType: 'code'
  },
};

export const paymentPlan = [
  {
    planName: 'test',
    duration: 1, 
    avgPerMonth: 3,
    totalPrice: 3
  },
  {
    planName: 'test',
    duration: 2, 
    totalPrice: 5,
    avgPerMonth: 2.50,
  },
  {
    planName: 'test',
    duration: 3, 
    totalPrice: 6,
    avgPerMonth: 2,
  },
  {
    planName: 'test',
    duration: 6, 
    totalPrice: 12,
    avgPerMonth: 2,
  },
  {
    planName: 'test',
    duration: 12, 
    totalPrice: 24,
    avgPerMonth: 2,
  }
]