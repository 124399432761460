import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  activeItem: string = 'dashboard';
  isHidden: boolean = false;

  @Output() footerHeight = new EventEmitter<number>();
  constructor(private router:Router, private elementRef: ElementRef){
    
  }
  setActive(item: string) {
    this.activeItem = item;
    switch(item){
      case 'dasboard':
        this.router.navigateByUrl('/pages/dashboard');
        break;
      case "feedback":
        this.router.navigateByUrl('/pages/feedback');
        break;
      case 'profile':
        this.router.navigateByUrl('/pages/profile');
        break;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isHidden = scrollTop > 100;  // Adjust the scroll value as needed
  }

  ngAfterViewInit() {
    const footerHeight = this.elementRef.nativeElement.offsetHeight;
    this.footerHeight.emit(footerHeight);
  }
}
