<mat-toolbar>
    <mat-toolbar-row *ngIf="!isMobile">
        <span class="toolbar-left" (click)="clickLogo()">
            <img src="assets/images/IQSparker_logo.png" />
        </span>
        <span class="toolbar-spacer"></span>
        <mat-icon class="search-icon" *ngIf="isDashboard">search</mat-icon>
        <input type="text" *ngIf="isDashboard" class="form-control dashboard-search" #searchInput
            id="exampleFormControlInput1" (input)="onSearchChange(searchInput.value)" placeholder="Search here..">

        <span class="toolbar-spacer"></span>
        <button mat-button class="login-button" (click)="goToLogin()" *ngIf="!(isLoggedIn$ | async)">Login /
            Register</button>&nbsp;&nbsp;
        <!-- <button mat-button class="login-button" (click)="signOut()"
            *ngIf="(isLoggedIn$ | async)">Logout</button>&nbsp;&nbsp;
        <button mat-button class="login-button" *ngIf="(isLoggedIn$ | async)" (click)="clickProfile()">Profile</button>
        <button mat-button class="login-button" *ngIf="(isLoggedIn$ | async)"
            (click)="clickFeedback()">Feedback</button> -->

        <div class="dropdown " *ngIf="(isLoggedIn$ | async)">
            <button *ngIf="this.avatar" class="avatar-circle" mat-icon-button [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger" matTooltip="More Actions" matTooltipPosition="below">
                <img class="avatar-circle" [src]="this.avatar" />
            </button>
            <button *ngIf="!this.avatar" mat-icon-button class="circle" [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger" matTooltip="More Actions" matTooltipPosition="below">

                {{ this.displayname| uppercase }}

            </button>
            <mat-menu #menu="matMenu">
                <!-- Dropdown menu options -->
                <button mat-menu-item (click)="clickProfile()">Profile</button>
                <button mat-menu-item (click)="clickFeedback()">Feedback</button>
                <button mat-menu-item (click)="signOut()">Logout</button>
            </mat-menu>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="isMobile">
        <span class="toolbar-left" (click)="clickLogo()">
            <img src="assets/images/IQSparker_logo.png" />
        </span>
        <span class="toolbar-spacer"></span>

        <span class="toolbar-spacer"></span>
        <button mat-button class="login-button" (click)="goToLogin()" *ngIf="!(isLoggedIn$ | async)">Login /
            Register</button>&nbsp;&nbsp;
        <!-- <button mat-button class="login-button" (click)="signOut()"
            *ngIf="(isLoggedIn$ | async)">Logout</button>&nbsp;&nbsp;
        <button mat-button class="login-button" *ngIf="(isLoggedIn$ | async)" (click)="clickProfile()">Profile</button>
        <button mat-button class="login-button" *ngIf="(isLoggedIn$ | async)"
            (click)="clickFeedback()">Feedback</button> -->

        <div class="dropdown " *ngIf="(isLoggedIn$ | async)">
            <button *ngIf="avatar" class="avatar-circle" mat-icon-button [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger" matTooltip="More Actions" matTooltipPosition="below">
                <img class="avatar-circle" [src]="avatar" />
            </button>
            <button *ngIf="!avatar" mat-icon-button class="circle" [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger" matTooltip="More Actions" matTooltipPosition="below">

                {{ this.displayname| uppercase }}

            </button>
            <mat-menu #menu="matMenu">
                <!-- Dropdown menu options -->
                <button mat-menu-item (click)="clickProfile()">Profile</button>
                <button mat-menu-item (click)="clickFeedback()">Feedback</button>
                <button mat-menu-item (click)="signOut()">Logout</button>
            </mat-menu>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="isMobile && isDashboard">
        <mat-icon class="search-icon" *ngIf="isDashboard">search</mat-icon>
        <input type="text" *ngIf="isDashboard" class="form-control dashboard-search" #searchInput
            id="exampleFormControlInput1" (input)="onSearchChange(searchInput.value)"
            (keydown.enter)="searchInput.blur()" placeholder="Search here..">
    </mat-toolbar-row>
</mat-toolbar>